/**
 * Adds a value to head at path if value is not null or undefined.
 * If value already existed at path, turn it into array and concat.
 *
 * Original Source in:  SignalsValidationUtils.js
 */
export function addValueAtPath(
  head: {[string]: any},
  path: string,
  value: mixed,
): {[string]: any} {
  const stack = path.split('.');

  if (value === undefined) {
    value = null;
  }

  for (let _index = 0; _index < stack.length - 1; _index++) {
    const parentKey = stack.shift();
    head = head[parentKey] || (head[parentKey] = {});
  }
  const leafKey = stack[0];

  if (!hasProp(head, leafKey)) {
    head[leafKey] = value;
  } else {
    const currentValue = head[leafKey];

    if (Array.isArray(currentValue)) {
      currentValue.push(value);
    } else {
      head[leafKey] = [currentValue, value];
    }
  }

  return head;
}

function hasProp(obj: mixed, key: string): boolean {
  return (
    // $FlowFixMe[method-unbinding] added when improving typing for this parameters
    typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, key)
  );
}
