/**
 * @format
 */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';
import {Alert, Button, Card, Form, Input, Space, Typography} from 'antd';
import styles from './App.module.less';
import {SingleColumnLayout} from '@cloudbridge/components';
import queryString from 'query-string';

var base64 = require('base-64');

const {Text} = Typography;

const routes = {
  login: '/auth/login',
  signup: '/auth/signup',
  logout: '/auth/logout',
  login_auth: '/auth/login-auth',
  signup_auth: '/auth/signup-auth',
};

type FormState = {
  username: string;
  password: string;
  code: string;
};

function FormAction(
  path: string,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
) {
  return async (values: FormState) => {
    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        // We are now logged in. Reloading the page will make the backend redirect to the target
        window.location.reload();
      } else if (response.status === 422) {
        setError('The invitation code does not match the record.');
      } else if (response.status >= 400 && response.status < 500) {
        const body = await response.json();
        const message = body.message || 'Unauthorised';
        setError(message);
      } else if (response.status >= 500 && response.status < 600) {
        setError('The service is not reachable at the moment');
      } else {
        setError('Unexpected response from the server');
      }
    } catch {
      setError('The service is not reachable at the moment');
    }
  };
}

function LoginForm() {
  const [error, setError] = React.useState<string | undefined>(undefined);

  const {search} = useLocation();
  const params = queryString.parse(search);

  var email = undefined;
  if (params && params.code) {
    try {
      email = JSON.parse(base64.decode(params.code)).email;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Form<FormState>
      size={'large'}
      name="login"
      initialValues={{username: email}}
      onFinish={FormAction(routes.login_auth, setError)}
    >
      <Form.Item
        name="username"
        rules={[{required: true, message: 'Please input your email'}]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{required: true, message: 'Please input your password'}]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" block={true} htmlType="submit">
          Log In
        </Button>
      </Form.Item>
      {error && <Alert message="Error" description={error} type="error" />}
    </Form>
  );
}

async function showSignupButton(
  setShowSignup: React.Dispatch<React.SetStateAction<boolean>>,
) {
  let url = '/tenant';
  try {
    let res = await fetch(url);
    if (res.ok) {
      let data = await res.json();
      setShowSignup(data.multitenantEnabled);
    }
  } catch (error) {
    console.log(error);
  }
}

function LoginCard() {
  const [showSignup, setShowSignup] = React.useState<boolean>(false);
  showSignupButton(setShowSignup);

  return (
    <Card className={styles.login_card}>
      <div className={styles.login_card_row}>
        <Space
          className={styles.login_card_column}
          direction="vertical"
          size="large"
        >
          <Typography.Title level={4}>Log In</Typography.Title>
          <LoginForm />
          {showSignup ? (
            <Text>
              {' '}
              Create New Account? Please{' '}
              <Link
                to={`${routes.signup}/${window.location.search}${window.location.hash}`}
              >
                sign up
              </Link>{' '}
              here.
            </Text>
          ) : null}
        </Space>
      </div>
    </Card>
  );
}

function SignUpForm() {
  const [error, setError] = React.useState<string | undefined>(undefined);

  const {search} = useLocation();
  const params = queryString.parse(search);

  var email = undefined;
  var invitationCode = undefined;
  if (params && params.code) {
    invitationCode = params.code;
    try {
      email = JSON.parse(base64.decode(invitationCode)).email;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Form<FormState>
      size={'large'}
      name="signup"
      initialValues={{username: email, code: invitationCode}}
      onFinish={FormAction(routes.signup_auth, setError)}
    >
      <Form.Item
        name="username"
        rules={[{required: true, message: 'Please input your email'}]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{required: true, message: 'Please input your password'}]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="code"
        rules={[{required: true, message: 'Please input your invitation code'}]}
      >
        <Input placeholder="Invitation Code" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" block={true} htmlType="submit">
          Sign Up
        </Button>
      </Form.Item>
      {error && <Alert message="Error" description={error} type="error" />}
    </Form>
  );
}

function SignUpCard() {
  return (
    <Card className={styles.login_card}>
      <div className={styles.login_card_row}>
        <Space
          className={styles.login_card_column}
          direction="vertical"
          size="large"
        >
          <Typography.Title level={4}>Sign Up</Typography.Title>
          <SignUpForm />
          <Text>
            {' '}
            Already have an account? Please{' '}
            <Link
              to={`${routes.login}/${window.location.search}${window.location.hash}`}
            >
              {' '}
              log in{' '}
            </Link>{' '}
            here.
          </Text>
        </Space>
      </div>
    </Card>
  );
}

export const App = () => {
  // TODO(lit): use relay to clear sessions for logout
  const Reload = () => {
    React.useEffect(() => {
      window.location.reload();
    });
    return null;
  };

  return (
    <Router>
      <SingleColumnLayout title={'Conversions API Gateway'}>
        <Switch>
          <Route exact path={routes.login}>
            <LoginCard />
          </Route>
          <Route exact path={routes.signup}>
            <SignUpCard />
          </Route>
          <Route exact path={routes.logout}>
            <Reload />
          </Route>
        </Switch>
      </SingleColumnLayout>
    </Router>
  );
};
